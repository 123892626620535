<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper first">
        <div class="cicle">
          <img src="./../images/cicle1.svg" class="img" />
          <img src="./../images/cicle2.svg" class="img" />
          <img src="./../images/cicle3.svg" class="img" />
        </div>
        <div class="column first">
          <router-link to="/" class="logo flex">
            <img src="./../images/logo.svg" class="img" />
            <div class="title">Promptstorms</div>
          </router-link>
          <div class="desc">
            {{
              $t(`Promptstorms is Unleashing
            Creativity, Enhancing Conversations.
            Your Source for Diverse AI Prompts.`)
            }}
          </div>
        </div>
        <div class="column second">
          <div class="hover-link" @click="scrollToSection('page')">
            <img src="./../images/up.svg" class="img" />
          </div>
          <div class="nav first">
            <div class="nav__item">
              <router-link to="/" class="desc">{{ $t(`Home`) }}</router-link>
            </div>
            <div class="nav__item">
              <div @click="scrollToSection('features')" class="desc hover-link">
                {{ $t(`Features`) }}
              </div>
            </div>
            <div class="nav__item">
              <router-link to="/product-list" class="desc">{{
                $t(`Prompts`)
              }}</router-link>
            </div>
          </div>
          <div class="nav">
            <div class="nav__item">
              <div
                @click="scrollToSection('weBelieve')"
                class="desc hover-link"
              >
                {{ $t(`We believe`) }}
              </div>
            </div>
            <div class="nav__item">
              <div
                @click="scrollToSection('howItWorks')"
                class="desc hover-link"
              >
                {{ $t(`How it works`) }}
              </div>
            </div>
            <div class="nav__item">
              <router-link to="/contacts" class="desc">{{
                $t(`Contacts`)
              }}</router-link>
            </div>
          </div>
          <div class="text">
            <div class="item">
              <div class="title">{{ $t(`Company name`) }}</div>
              <div class="desc">{{ $parent.settings.company_name }}</div>
            </div>
            <div class="item">
              <div class="title">{{ $t(`Reg number`) }}</div>
              <div class="desc">{{ $parent.settings.company_reg_number }}</div>
            </div>
          </div>
          <div class="text">
            <div class="item">
              <div class="title">{{ $t(`Email`) }}</div>
              <div class="desc">{{ $parent.settings.support_email }}</div>
            </div>
            <div class="item">
              <div class="title">{{ $t(`Address`) }}</div>
              <div class="desc">{{ $parent.settings.company_address }}</div>
            </div>
          </div>
        </div>
        <div class="column third">
          <div class="bottom">
            <ul class="payment-methods-list">
              <li v-for="image in allImages" :key="image" class="image-wrapper">
                <img :src="image" class="support-icon img" />
              </li>

              <!-- <li class="item">
                  <img src="./../images/mc_symbol.svg" class="img"/>
                </li>
                <li class="item">
                  <img src="./../images/visa.svg" class="img"/>
                </li>
                <li class="item">
                  <img src="./../images/3ds.png" class="img"/>
                </li>
                <li class="item">
                  <img src="./../images/methods/eps.svg" class="img" />
                </li>
                <li class="item">
                  <img src="./../images/methods/trustly.svg" class="img" />
                </li>
                <li class="item">
                  <img src="./../images/methods/giropay.svg" class="img" />
                </li>
                <li class="item">
                  <img src="./../images/methods/blik.svg" class="img" />
                </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="wrapper copyright">
        <div class="column">
          <ul class="nav">
            <li
              v-for="link in $parent.socialLinks"
              :key="link.image"
              class="nav__item nav__item-text-pages"
            >
              <a :href="link.url" class="desc" target="_blank">
                <img :src="link.image" :alt="link.url" class="img" />
              </a>
            </li>
          </ul>
        </div>
        <div class="column">
          <div class="desc">{{ $parent.settings.copyright }}</div>
        </div>
        <div class="column">
          <ul class="nav" v-if="$parent.textPageList.length">
            <template v-for="item in $parent.textPageList">
              <li class="nav__item nav__item-text-pages" :key="item.id">
                <a class="desc" @click="$parent.goToPage(item.id)">
                  {{ item.title }}
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {};
  },
  computed: {
    paymentMethodsList() {
      if (!this.$parent.allPaymentMethods) return [];
      return this.$parent.allPaymentMethods;
    },
    allImages() {
      return this.paymentMethodsList.flatMap(this.getImages);
    },
  },
  methods: {
    getImages(method) {
      const images = [];
      if (method.code === "card" && method.title === "Visa/Mastercard") {
        images.push(
          require("@/images/visa.svg"),
          require("@/images/mc_symbol.svg")
        );
        // images.push(require('@/images/3ds.png'));
      } else if (method.code === "eps" || method.title === "EPS") {
        images.push(require("@/images/methods/eps.svg"));
      } else if (method.code === "trustly" || method.title === "Trustly") {
        images.push(require("@/images/methods/trustly.svg"));
      } else if (method.code === "giropay" || method.title === "GiroPay") {
        images.push(require("@/images/methods/giropay.svg"));
      } else if (method.code === "blik" || method.title === "Blik") {
        images.push(require("@/images/methods/blik.svg"));
      } else if (method.code === "paidby" || method.title === "Paidby") {
        images.push(require("@/images/paidby.svg"));
      } else if (method.code === "klarna" || method.title === "Klarna") {
        images.push(require("@/images/klarna.svg"));
      } else if (
        method.code === "sofortuberweisung" ||
        method.title === "Sofortuberweisung"
      ) {
        images.push(require("@/images/sofortuber.svg"));
      } else if (method.code === "ideal" || method.title === "iDEAL") {
        images.push(require("@/images/ideal.svg"));
      } else if (
        method.code === "bancontact" ||
        method.title === "Bancontact"
      ) {
        images.push(require("@/images/bancontact.svg"));
      }
      else if (method.code === "skrill_wallet" || method.title === "skrill") {
        images.push(require("@/images/skrill.svg"));
      } else if (method.code === "mbway" || method.title === "MBWay") {
        images.push(require("@/images/mbway.svg"));
      } else if (
        method.code === "paysafecard" ||
        method.title === "Paysafecard"
      ) {
        images.push(require("@/images/paysafecard.svg"));
      }
      return images;
    },
    scrollToSection(section) {
      this.$emit("scrollToSection", section);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.footer .payment-methods-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .image-wrapper {
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    max-width: 70px;
  }

  .support-icon.img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}
</style>
